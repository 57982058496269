var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "caption" },
    _vm._l(_vm.steps, function(step, index) {
      return _c(
        "v-card",
        { key: index, staticClass: "pa-2", attrs: { tile: "", outlined: "" } },
        [
          _vm.type === "ingredients"
            ? _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", sm: "1" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "body-1" },
                        [
                          step.starred
                            ? _c("v-icon", [_vm._v("mdi-star")])
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", sm: "1" }
                    },
                    [
                      _c("div", { staticClass: "body-1" }, [
                        _vm._v(_vm._s(step.title))
                      ])
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", sm: "1" }
                    },
                    [
                      _c("div", { staticClass: "body-1" }, [
                        _vm._v(_vm._s(step.group))
                      ]),
                      !step.group
                        ? _c("div", { staticClass: "body-1" }, [_vm._v("-")])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", sm: "1" }
                    },
                    [
                      _c("div", { staticClass: "body-1" }, [
                        _vm._v(
                          _vm._s(step.qty) +
                            " " +
                            _vm._s(_vm._f("filterMeasure")(step.units))
                        )
                      ])
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", sm: "1" }
                    },
                    [
                      step.variant
                        ? _c("div", { staticClass: "body-1" }, [
                            _vm._v(
                              _vm._s(_vm._f("filterVariant")(step.variant))
                            )
                          ])
                        : _vm._e(),
                      !step.variant
                        ? _c("div", { staticClass: "body-1" }, [_vm._v("-")])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", sm: "1" }
                    },
                    [
                      step.alternative
                        ? _c("div", { staticClass: "body-1" }, [
                            _vm._v(_vm._s(step.alternative.allergen))
                          ])
                        : _vm._e(),
                      !step.alternative
                        ? _c("div", { staticClass: "body-1" }, [_vm._v("-")])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", sm: "1" }
                    },
                    [
                      step.alternative
                        ? _c("div", { staticClass: "body-1" }, [
                            _vm._v(_vm._s(step.alternative.title))
                          ])
                        : _vm._e(),
                      !step.alternative
                        ? _c("div", { staticClass: "body-1" }, [_vm._v("-")])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", sm: "1" }
                    },
                    [
                      _c("div", { staticClass: "body-1" }, [
                        _vm._v(
                          _vm._s(step.include_in_shopping_list ? "Sí" : "No")
                        )
                      ])
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", sm: "1" }
                    },
                    [
                      step.alternative
                        ? _c("div", { staticClass: "body-1" }, [
                            _vm._v(
                              _vm._s(
                                step.alternative.include_in_shopping_list
                                  ? "Sí"
                                  : "No"
                              )
                            )
                          ])
                        : _vm._e(),
                      !step.alternative
                        ? _c("div", { staticClass: "body-1" }, [_vm._v("-")])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-right",
                      attrs: { cols: "12", sm: "1" }
                    },
                    [
                      _vm.steps.length > 1 && index !== 0
                        ? _c(
                            "v-icon",
                            {
                              attrs: { color: "gray" },
                              on: {
                                click: function($event) {
                                  return _vm.move("up", index)
                                }
                              }
                            },
                            [_vm._v("mdi-arrow-up-circle")]
                          )
                        : _vm._e(),
                      _vm.steps.length > 1 && index !== _vm.steps.length - 1
                        ? _c(
                            "v-icon",
                            {
                              attrs: { color: "gray" },
                              on: {
                                click: function($event) {
                                  return _vm.move("down", index)
                                }
                              }
                            },
                            [_vm._v("mdi-arrow-down-circle")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-right",
                      attrs: { cols: "12", sm: "2" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-1",
                          attrs: { depressed: "" },
                          on: {
                            click: function($event) {
                              return _vm.edit(index)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-pencil")])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { depressed: "" },
                          on: {
                            click: function($event) {
                              return _vm.remove(index)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-delete")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c("v-col", { attrs: { cols: "12", sm: "1" } }, [
                    _c("div", { staticClass: "body-1" }, [
                      _vm._v(_vm._s(step.order))
                    ])
                  ]),
                  _c("v-col", { attrs: { cols: "12", sm: "2" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "body-1 link",
                        on: {
                          click: function($event) {
                            return _vm.edit(index)
                          }
                        }
                      },
                      [_vm._v(_vm._s(step.title))]
                    )
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "2" } },
                    _vm._l(step.alternative, function(alternative, index) {
                      return _c("div", { key: index, staticClass: "body-1" }, [
                        _vm._v(_vm._s(alternative.title))
                      ])
                    }),
                    0
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "1" } },
                    _vm._l(step.alternative, function(alternative, index) {
                      return _c("div", { key: index, staticClass: "body-1" }, [
                        _vm._v(
                          _vm._s(alternative.qty) +
                            " " +
                            _vm._s(_vm._f("filterMeasure")(alternative.units))
                        )
                      ])
                    }),
                    0
                  ),
                  _c("v-col", { attrs: { cols: "12", sm: "3" } }, [
                    _c("div", { staticClass: "body-1 description" }, [
                      _vm._v(_vm._s(step.description))
                    ])
                  ]),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-right",
                      attrs: { cols: "12", sm: "1" }
                    },
                    [
                      _vm.steps.length > 1 && index !== 0
                        ? _c(
                            "v-icon",
                            {
                              attrs: { color: "gray" },
                              on: {
                                click: function($event) {
                                  return _vm.move("up", index)
                                }
                              }
                            },
                            [_vm._v("mdi-arrow-up-circle")]
                          )
                        : _vm._e(),
                      _vm.steps.length > 1 && index !== _vm.steps.length - 1
                        ? _c(
                            "v-icon",
                            {
                              attrs: { color: "gray" },
                              on: {
                                click: function($event) {
                                  return _vm.move("down", index)
                                }
                              }
                            },
                            [_vm._v("mdi-arrow-down-circle")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-right",
                      attrs: { cols: "12", sm: "2" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { depressed: "" },
                          on: {
                            click: function($event) {
                              return _vm.edit(index)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-pencil")])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { depressed: "" },
                          on: {
                            click: function($event) {
                              return _vm.remove(index)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-delete")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }