var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { flat: "", app: "", color: "white", light: "" } },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.menu($event)
              }
            }
          }),
          _c(
            "div",
            { staticClass: "pr-2 pl-2" },
            [
              _c("v-icon", { attrs: { color: "primary" } }, [
                _vm._v("mdi-ballot")
              ])
            ],
            1
          ),
          _c(
            "v-toolbar-title",
            { staticStyle: { color: "#84264f", "font-weight": "bold" } },
            [
              _vm._v("Recetas\n      "),
              _c(
                "v-chip",
                { staticClass: "ml-4", attrs: { color: "primary" } },
                [_vm._v(_vm._s(_vm.totalNumRecipes$))]
              )
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-btn",
            { attrs: { medium: "", color: "primary", to: "/recetas/nueva" } },
            [_vm._v("Añadir receta")]
          )
        ],
        1
      ),
      _vm.error$.isError
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v("\n    " + _vm._s(_vm.error$.message) + "\n  ")
          ])
        : _vm._e(),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "290" },
              model: {
                value: _vm.delDialog,
                callback: function($$v) {
                  _vm.delDialog = $$v
                },
                expression: "delDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v("Borrar")]),
                  _c("v-card-text", { staticClass: "subtitle-1" }, [
                    _vm._v("Deseas borrar esta receta ?")
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function($event) {
                              _vm.delDialog = false
                            }
                          }
                        },
                        [_vm._v("Cancelar")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.deleteRecipe()
                            }
                          }
                        },
                        [_vm._v("Sí")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "9" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      "prepend-inner-icon": "mdi-magnify",
                      "background-color": "red",
                      label: "Buscar receta",
                      dense: "",
                      outlined: ""
                    },
                    on: { input: _vm.searchChange },
                    model: {
                      value: _vm.searchIngredients,
                      callback: function($$v) {
                        _vm.searchIngredients = $$v
                      },
                      expression: "searchIngredients"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "3" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.recipesFilterOptions,
                      "item-text": "label",
                      "item-value": "value",
                      label: "Filtrar",
                      dense: "",
                      outlined: ""
                    },
                    on: { change: _vm.selectFilter }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-toolbar",
            { attrs: { color: "secondary", dark: "", flat: "", dense: "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", sm: "4" }
                    },
                    [_c("div", { staticClass: "body-1" }, [_vm._v("Título")])]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", sm: "2" }
                    },
                    [_c("div", { staticClass: "body-1" }, [_vm._v("Autor")])]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", sm: "1" }
                    },
                    [
                      _c("div", { staticClass: "body-1" }, [
                        _vm._v("Tipo Dieta")
                      ])
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", sm: "2" }
                    },
                    [
                      _c("div", { staticClass: "body-1" }, [
                        _vm._v("Tipo receta")
                      ])
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", sm: "1" }
                    },
                    [_c("div", { staticClass: "body-1" }, [_vm._v("Estado")])]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", sm: "1" }
                    },
                    [_c("div", { staticClass: "body-1" }, [_vm._v("Fecha")])]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", sm: "1" }
                    },
                    [_c("div", { staticClass: "body-1" })]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("ItemList", {
            attrs: {
              items: _vm.recipes$,
              titleAction: { type: "EDIT_RECIPE", icon: "mdi-pencil" },
              actions: [
                { type: "VIEW_RECIPE", icon: "mdi-eye" },
                { type: "DELETE_RECIPE", icon: "mdi-delete" }
              ],
              type: "recipe",
              dragAndDropActive: true,
              totalItems: _vm.totalNumRecipes$
            },
            on: {
              actionTrigger: function($event) {
                return _vm.onActionTrigger($event)
              },
              paginationChange: function($event) {
                return _vm.paginationChange($event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }