<template>
  <div>
    <v-app-bar flat app color="white" light>
      <v-app-bar-nav-icon @click.stop="menu"></v-app-bar-nav-icon>
      <div class="pr-2 pl-2">
        <v-icon color="primary">mdi-ballot</v-icon>
      </div>

      <v-toolbar-title style="color:#84264f; font-weight:bold"
        >Recetas
        <v-chip color="primary" class="ml-4">{{ totalNumRecipes$ }}</v-chip>
      </v-toolbar-title>
      <v-spacer />
      <v-btn medium color="primary" to="/recetas/nueva">Añadir receta</v-btn>
    </v-app-bar>

    <v-alert v-if="error$.isError" type="error">
      {{ error$.message }}
    </v-alert>

    <v-container fluid>
      <v-dialog v-model="delDialog" max-width="290">
        <v-card>
          <v-card-title>Borrar</v-card-title>
          <v-card-text class="subtitle-1"
            >Deseas borrar esta receta ?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="delDialog = false"
              >Cancelar</v-btn
            >
            <v-btn color="primary" @click="deleteRecipe()">Sí</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row>
        <v-col cols="12" sm="9">
          <v-text-field
            v-model="searchIngredients"
            @input="searchChange"
            prepend-inner-icon="mdi-magnify"
            background-color="red"
            label="Buscar receta"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-select
            :items="recipesFilterOptions"
            @change="selectFilter"
            item-text="label"
            item-value="value"
            label="Filtrar"
            dense
            outlined
          ></v-select>
        </v-col>
      </v-row>

      <v-toolbar color="secondary" dark flat dense>
        <v-row>
          <v-col cols="12" sm="4" class="text-center">
            <div class="body-1">Título</div>
          </v-col>
          <v-col cols="12" sm="2" class="text-center">
            <div class="body-1">Autor</div>
          </v-col>
          <v-col cols="12" sm="1" class="text-center">
            <div class="body-1">Tipo Dieta</div>
          </v-col>
          <v-col cols="12" sm="2" class="text-center">
            <div class="body-1">Tipo receta</div>
          </v-col>
          <v-col cols="12" sm="1" class="text-center">
            <div class="body-1">Estado</div>
          </v-col>
          <v-col cols="12" sm="1" class="text-center">
            <div class="body-1">Fecha</div>
          </v-col>
          <v-col cols="12" sm="1" class="text-center">
            <div class="body-1"></div>
          </v-col>
        </v-row>
      </v-toolbar>

      <ItemList
        :items="recipes$"
        :titleAction="{ type: 'EDIT_RECIPE', icon: 'mdi-pencil' }"
        :actions="[
          { type: 'VIEW_RECIPE', icon: 'mdi-eye' },
          { type: 'DELETE_RECIPE', icon: 'mdi-delete' },
        ]"
        type="recipe"
        :dragAndDropActive="true"
        :totalItems="totalNumRecipes$"
        @actionTrigger="onActionTrigger($event)"
        @paginationChange="paginationChange($event)"
      />
    </v-container>
  </div>
</template>

<script>
import store from "../../store";
import ItemList from "@/components/itemList";
import { RECIPES_FILTER, TABLE_NUM_ITEMS } from "../../constants";
import * as CryptoJS from "crypto-js";

export default {
  data() {
    return {
      delDialog: false,
      itemIdToDelete: null,
      recipesFilterOptions: RECIPES_FILTER,
      conditions: "",
      searchIngredients: null,
      filter: null,
    };
  },
  components: { ItemList },
  computed: {
    recipes$() {
      const recipes = store.getters["recipes/items"];
      // map author
      return recipes.map((recipe) => ({
        ...recipe,
        authorId: this.getUserFullNameById(recipe.author_id),
      }));
    },
    totalNumRecipes$() {
      return parseInt(store.getters["recipes/totalItems"]);
    },
    users$() {
      return store.getters["users/allUsers"];
    },
    error$() {
      return store.getters["recipes/error"];
    },
  },
  methods: {
    menu() {
      store.dispatch("user/ToggleMenu");
    },
    onActionTrigger({ event }) {
      switch (event.action) {
        case "DELETE_RECIPE":
          this.delDialog = true;
          this.itemIdToDelete = event.id;
          break;
        case "EDIT_RECIPE":
          this.$router.push(`/recetas/editar/${event.id}`);
          break;
        case "VIEW_RECIPE":
          this.previewRecipe(event.id);
          break;
      }
    },

    previewRecipe(id) {
      let token = localStorage.getItem("user-token") || "";
      let encodedSecret = this.enc(token);
      const baseUrlFront = process.env.VUE_APP_FRONT_END_URL;
      let win = window.open(
        baseUrlFront + `/preview/${id}/?token=` + encodedSecret,
        "_blank"
      );
      win.focus();
    },

    enc(plainText) {
      let b64 = CryptoJS.AES.encrypt(
        plainText,
        process.env.VUE_APP_SECRET
      ).toString();
      let e64 = CryptoJS.enc.Base64.parse(b64);
      let eHex = e64.toString(CryptoJS.enc.Hex);
      return eHex;
    },

    deleteRecipe() {
      store
        .dispatch("recipes/DeleteRecipe", { id: this.itemIdToDelete })
        .then(() => {
          this.delDialog = false;
        });
    },
    paginationChange(data) {
      this.search(
        data.event * TABLE_NUM_ITEMS,
        TABLE_NUM_ITEMS,
        this.searchIngredients,
        this.filter
      );
    },
    selectFilter(evt) {
      this.filter = evt;
      this.search(0, TABLE_NUM_ITEMS, this.searchIngredients, this.filter);
    },
    searchChange(evt) {
      this.searchIngredients = evt;
      this.search(0, TABLE_NUM_ITEMS, evt, this.filter);
    },
    search(offset, limit, search, filter) {
      let query = "";
      if (search) {
        query += `%20title%20LIKE%20'%25${search}%25'`;
      }
      if (filter) {
        if (query.length) {
          switch (filter) {
            case "vegan": {
              query += `%20AND%20vegan%20%3D%201`;
              break;
            }
            case "!vegan": {
              query += `%20AND%20vegan%20%3D%200`;
              break;
            }
            default: {
              query += `%20AND%20${filter}%20LIKE%20'%251%25'`;
              break;
            }
          }
        } else {
          switch (filter) {
            case "vegan": {
              query += `%20vegan%20%3D%201`;
              break;
            }
            case "!vegan": {
              query += `%20vegan%20%3D%200`;
              break;
            }
            default: {
              query = `%20${filter}%20LIKE%20'%251%25'`;
              break;
            }
          }
        }
      }

      const order = `created DESC`;

      store
        .dispatch("recipes/LoadRecipes", {
          offset: offset,
          limit: limit,
          conditions: query,
          order,
        })
        .then(() => {});
    },
    getUserFullNameById(id) {
      const user = this.users$.filter((user) => user.id === id)[0] || {};
      return user.first_name ? `${user.first_name} ${user.last_name}` : null;
    },
  },
};
</script>

<style></style>
