<template>
  <div class="caption">
    <v-card tile class="pa-2" outlined v-for="(step, index) in steps" :key="index">
      <v-row align="center" v-if="type === 'ingredients'">
        <v-col cols="12" sm="1" class="text-center">
          <div class="body-1">
            <v-icon v-if="step.starred">mdi-star</v-icon>
          </div>
        </v-col>
        <v-col cols="12" sm="1" class="text-center">
          <div class="body-1">{{ step.title }}</div>
        </v-col>
        <v-col cols="12" sm="1" class="text-center">
          <div class="body-1">{{ step.group }}</div>
          <div class="body-1" v-if="!step.group">-</div>
        </v-col>
        <v-col cols="12" sm="1" class="text-center">
          <div class="body-1">{{ step.qty }} {{ step.units | filterMeasure }}</div>
        </v-col>
        <v-col cols="12" sm="1" class="text-center">
          <div class="body-1" v-if="step.variant">{{ step.variant | filterVariant }}</div>
          <div class="body-1" v-if="!step.variant">-</div>
        </v-col>
        <v-col cols="12" sm="1" class="text-center">
          <div class="body-1" v-if="step.alternative">{{ step.alternative.allergen }}</div>
          <div class="body-1" v-if="!step.alternative">-</div>
        </v-col>
        <v-col cols="12" sm="1" class="text-center">
          <div class="body-1" v-if="step.alternative">{{ step.alternative.title }}</div>
          <div class="body-1" v-if="!step.alternative">-</div>
        </v-col>
        <v-col cols="12" sm="1" class="text-center">
          <div class="body-1">{{step.include_in_shopping_list ? 'Sí' : 'No'}}</div>
        </v-col>
        <v-col cols="12" sm="1" class="text-center">
          <div
            class="body-1"
            v-if="step.alternative"
          >{{step.alternative.include_in_shopping_list ? 'Sí' : 'No'}}</div>
          <div class="body-1" v-if="!step.alternative">-</div>
        </v-col>
        <v-col cols="12" sm="1" class="text-right">
          <v-icon
            v-if="steps.length > 1 && index !== 0"
            color="gray"
            @click="move('up', index)"
          >mdi-arrow-up-circle</v-icon>
          <v-icon
            v-if="steps.length > 1 && index !== steps.length - 1"
            color="gray"
            @click="move('down', index)"
          >mdi-arrow-down-circle</v-icon>
        </v-col>
        <v-col cols="12" sm="2" class="text-right">
          <v-btn depressed @click="edit(index)" class="mr-1">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn depressed @click="remove(index)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row align="center" v-else>
        <v-col cols="12" sm="1">
          <div class="body-1">{{ step.order }}</div>
        </v-col>
        <v-col cols="12" sm="2">
          <div class="body-1 link" @click="edit(index)">{{ step.title }}</div>
        </v-col>

        <v-col cols="12" sm="2">
          <div
            v-for="(alternative, index) in step.alternative"
            :key="index"
            class="body-1"
          >{{ alternative.title }}</div>
        </v-col>
        <v-col cols="12" sm="1">
          <div
            v-for="(alternative, index) in step.alternative"
            :key="index"
            class="body-1"
          >{{ alternative.qty }} {{ alternative.units | filterMeasure }}</div>
        </v-col>
        <v-col cols="12" sm="3">
          <div class="body-1 description">{{ step.description }}</div>
        </v-col>
        <v-col cols="12" sm="1" class="text-right">
          <v-icon
            v-if="steps.length > 1 && index !== 0"
            color="gray"
            @click="move('up', index)"
          >mdi-arrow-up-circle</v-icon>
          <v-icon
            v-if="steps.length > 1 && index !== steps.length - 1"
            color="gray"
            @click="move('down', index)"
          >mdi-arrow-down-circle</v-icon>
        </v-col>
        <v-col cols="12" sm="2" class="text-right">
          <v-btn depressed @click="edit(index)" class="mr-2">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn depressed @click="remove(index)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { UNITS_OF_MEASURE, VARIANT } from "../constants";

export default {
  props: ["steps", "type"],
  methods: {
    move(direction, index) {
      this.$emit("move", { direction, index });
    },
    remove(index) {
      this.$emit("remove", { index });
    },
    edit(index) {
      this.$emit("edit", { index });
    }
  },
  filters: {
    filterMeasure(unit) {
      return (
        (UNITS_OF_MEASURE.filter(measure => measure.value === unit)[0] || {})
          .label || UNITS_OF_MEASURE.find(u => u.value === "u").label
      );
    },
    filterVariant(variantStep) {
      return (VARIANT.find(variant => variantStep.toLowerCase() === variant.value.toLowerCase()) || {})
        .label;
    }
  }
};
</script>

<style>
.description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.link {
  cursor: pointer;
}
</style>