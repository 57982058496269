<template>
  <div class="caption">
    <v-app-bar flat app color="white" light>
      <v-app-bar-nav-icon @click.stop="menu"></v-app-bar-nav-icon>
      <div class="pr-2 pl-2">
        <v-icon color="primary">mdi-ballot</v-icon>
      </div>
      <v-toolbar-title>
        <span style="color:#84264f; font-weight:bold">Recetas</span> / Editar
        receta
      </v-toolbar-title>
    </v-app-bar>

    <v-alert v-if="error$.isError" type="error">{{ error$.message }}</v-alert>

    <v-snackbar v-model="updatedSuccess">
      Receta guardada correctamente
      <v-btn color="pink" text @click="updatedSuccess = false">Cerrar</v-btn>
    </v-snackbar>

    <v-container fluid>
      <!-- DIALOG GRUPO DE INGREDIENTES -->
      <v-dialog v-model="dialogGroupIngredient" persistent max-width="600px">
        <v-card>
          <v-toolbar dark color="primary" flat>
            <v-toolbar-title>Añadir Grupo de ingredientes</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn icon @click="dialogGroupIngredient = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-alert v-if="hasErrorGroupIngredient" type="error"
                    >Error añadiendo el grupo de ingredientes.</v-alert
                  >
                </v-col>
              </v-row>

              <!-- SELECTOR GRUPO DE INGREDIENTES -->
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="groupIngredient.id"
                    label="Nombre del grupo de ingredientes"
                    :items="groupIngredients$"
                    item-text="name"
                    item-value="id"
                    @change="selectedGroupIngredients"
                    outlined
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <!-- INGREDIENTES DEL GRUPO -->
              <v-row
                v-for="(ingredient, index) in groupIngredient.ingredients"
                :key="index"
              >
                <v-col cols="12" class="title">{{ ingredient.name }}</v-col>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      dense
                      v-model="ingredient.qty"
                      type="number"
                      outlined
                      label="Cantidad"
                      required
                      hint="Para 4 personas"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      dense
                      v-model="ingredient.units"
                      :items="unidadMedida"
                      item-text="label"
                      item-value="value"
                      label="Medida"
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      dense
                      v-model="ingredient.variant"
                      :items="varianteIng"
                      item-text="label"
                      item-value="value"
                      label="Variante"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>

                <v-col cols="12" v-if="ingredient.allergen_id">
                  <div class="subtitle"
                    >Alérgeno:
                    {{ getAllergenById(ingredient.allergen_id).name }}</div
                  >
                </v-col>

                <template v-if="ingredient.allergen_id">
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="ingredient.alternative.ingredientId"
                      label="Nombre ingrediente alternativo"
                      :items="
                        ingredientsWithoutAllergen(ingredient.allergen_id)
                      "
                      item-text="name"
                      item-value="id"
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        dense
                        v-model="ingredient.alternative.qty"
                        type="number"
                        outlined
                        label="Cantidad"
                        required
                        hint="Para 4 personas"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        dense
                        v-model="ingredient.alternative.units"
                        :items="unidadMedida"
                        item-text="label"
                        item-value="value"
                        label="Medida"
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        dense
                        v-model="ingredient.alternative.variant"
                        :items="varianteIng"
                        item-text="label"
                        item-value="value"
                        label="Variante"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                </template>

                <v-flex xs12>
                  <v-switch
                    color="primary"
                    v-model="ingredient.starred"
                    class="ma-2"
                    label="Es un ingrediente estrella"
                  ></v-switch>
                </v-flex>

                <v-flex xs12 v-if="ingredient.allergen_id">
                  <v-switch
                    color="primary"
                    v-model="ingredient.impliesRecipeModification"
                    class="ma-2"
                    label="Este ingrediente implica una modificación de la receta"
                  ></v-switch>
                </v-flex>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <div class="flex-grow-1"></div>

            <v-btn
              depressed
              color="gray"
              text
              @click="dialogGroupIngredient = false"
              >Cancelar</v-btn
            >
            <v-btn color="success" @click="createGroupIngredient()"
              >Guardar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- DIALOG INGREDIENT -->
      <v-dialog v-model="dialogIngredient" persistent max-width="600px">
        <v-card>
          <v-toolbar dark color="primary" flat>
            <v-toolbar-title v-if="stepMode === 'create'"
              >Añadir ingrediente</v-toolbar-title
            >
            <v-toolbar-title v-else>Editar ingrediente</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn icon @click="dialogIngredient = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-alert v-if="hasErrorIngredient" type="error"
                    >Error añadiendo el ingrediente.</v-alert
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    :disabled="stepMode !== 'create'"
                    v-model="ingredient.ingredientId"
                    label="Nombre ingrediente"
                    :items="
                      ingredients$.filter(
                        ({ vegan }) =>
                          (vegan && this.recipe.vegan) || !this.recipe.vegan
                      )
                    "
                    item-text="name"
                    item-value="id"
                    @change="selectedIngredient"
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-row v-if="ingredientType === 'SIMPLE'">
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="ingredient.qty"
                      hint="Para 4 personas"
                      outlined
                      label="Cantidad"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      v-model="ingredient.units"
                      :items="unidadMedida"
                      item-text="label"
                      item-value="value"
                      label="Medida"
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      v-model="ingredient.variant"
                      :items="varianteIng"
                      item-text="label"
                      item-value="value"
                      label="Variante"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row v-if="ingredientType === 'COMPOUND'">
                  <v-col cols="12">
                    <v-text-field
                      v-model="ingredient.qty"
                      outlined
                      label="Factor"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-col cols="12" v-if="selectedIngredientContainsAllergen">
                  <div class="subtitle"
                    >Alérgeno: {{ selectedAllergen.name }}</div
                  >
                </v-col>

                <template v-if="selectedIngredientContainsAllergen">
                  <v-col cols="12">
                    <v-autocomplete
                      :disabled="stepMode !== 'create'"
                      v-model="ingredient.alternative.ingredientId"
                      @change="selectedAlternativeIngredient"
                      label="Nombre ingrediente alternativo"
                      :items="ingredientsWithoutAllergen()"
                      item-text="name"
                      item-value="id"
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-row v-if="ingredientAlternativeType === 'SIMPLE'">
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="ingredient.alternative.qty"
                        type="number"
                        outlined
                        label="Cantidad"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        v-model="ingredient.alternative.units"
                        :items="unidadMedida"
                        item-text="label"
                        item-value="value"
                        label="Medida"
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        v-model="ingredient.alternative.variant"
                        :items="varianteIng"
                        item-text="label"
                        item-value="value"
                        label="Variante"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="ingredientAlternativeType === 'COMPOUND'">
                    <v-col cols="12">
                      <v-text-field
                        v-model="ingredient.alternative.qty"
                        outlined
                        label="Factor"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </template>

                <v-flex xs12>
                  <v-switch
                    color="primary"
                    v-model="ingredient.starred"
                    class="ma-2"
                    label="Es un ingrediente estrella"
                  ></v-switch>
                </v-flex>
                <v-flex xs12 v-if="selectedIngredientContainsAllergen">
                  <v-switch
                    color="primary"
                    v-model="ingredient.impliesRecipeModification"
                    class="ma-2"
                    label="Este ingrediente implica una modificación de la receta"
                  ></v-switch>
                </v-flex>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <div class="flex-grow-1"></div>
            <v-btn
              depressed
              color="gray"
              text
              @click="
                dialogIngredient = false;
                selectedIngredientContainsAllergen = false;
              "
              >Cancelar</v-btn
            >
            <v-btn color="success" @click="createIngredient()">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- DIALOG STEP -->
      <v-dialog v-model="dialogStep" persistent max-width="800px">
        <v-card>
          <v-toolbar dark color="primary" flat>
            <v-toolbar-title v-if="stepMode === 'create'"
              >Añadir paso</v-toolbar-title
            >
            <v-toolbar-title v-else>Editar paso</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn icon @click="dialogStep = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-alert v-if="hasErrorStep" type="error"
                    >Error añadiendo el paso.</v-alert
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="step.step"
                    :items="tipoPaso"
                    item-text="label"
                    item-value="value"
                    label="Tipo de paso"
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="step.title"
                    outlined
                    label="Título"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-textarea
                    v-model="step.description"
                    outlined
                    name="input-7-1"
                    label="Descripción"
                  ></v-textarea>
                </v-col>
              </v-row>

              <p
                v-if="
                  stepTypeCreation === 'PREVIOUS_PREPARATION'
                    ? ingredientsPreparacion.length
                    : ingredientsElaboracion.length +
                      ingredientsPreparacion.length
                "
                >Ingredientes que son necesários en este paso</p
              >
              <p v-else>No hay ingredientes creados!</p>
              <v-row>
                <v-col
                  cols="6"
                  style="padding-top: 0; padding-bottom: 0;"
                  v-for="(ingredient, index) in stepTypeCreation ===
                    'PREVIOUS_PREPARATION' &&
                  (step.alternative && step.alternative.length)
                    ? ingredientsPreparacion
                    : [...ingredientsPreparacion, ...ingredientsElaboracion]"
                  :key="index"
                >
                  <v-row
                    v-if="
                      step.alternative &&
                        step.alternative.length &&
                        step.alternative[index]
                    "
                  >
                    <v-col cols="12" style="padding-top: 0; padding-bottom: 0;">
                      <v-switch
                        color="primary"
                        v-model="step.alternative[index].active"
                        @change="selectIngredientId(ingredient, index)"
                      >
                        <template v-slot:label>
                          <div>
                            {{ ingredient.title }} ({{ ingredient.qty }}
                            {{
                              unidadMedida.find(
                                (unit) => unit.value === ingredient.units
                              ).label
                            }})
                            <span v-if="ingredient.group"
                              >- {{ ingredient.group }}</span
                            >
                            <span
                              v-if="
                                ingredient.alternative &&
                                  ingredient.alternative.allergen
                              "
                              >(alérgeno: {{ ingredient.alternative.allergen }})
                              - alternativo:
                              {{ ingredient.alternative.title }}</span
                            >
                          </div>
                        </template>
                      </v-switch>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="
                        ingredient.alternative &&
                          step.alternative[index] &&
                          step.alternative[index].active &&
                          ingredient.impliesRecipeModification
                      "
                    >
                      <v-textarea
                        v-model="step.alternative[index].description"
                        outlined
                        name="input-7-1"
                        label="Descripción ingrediente alternativo"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-alert v-if="isErrorModal" type="error">{{
                errorMessage
              }}</v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn depressed color="gray" text @click="dialogStep = false"
              >Cancelar</v-btn
            >
            <v-btn color="success" @click="createStep()">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- INICIO DE RECETA -->

      <v-row>
        <v-col>
          <img class="imagen" v-if="recipe.imageUrl" :src="recipe.imageUrl" />
        </v-col>
        <v-col>
          <v-flex xs12>
            <v-text-field
              v-model="recipe.title"
              background-color="white"
              label="Titulo receta"
              placeholder
              @change="updateRecipe()"
              outlined
            ></v-text-field>
          </v-flex>

          <v-flex xs12>
            <v-text-field
              v-model="recipe.preparationTime"
              type="number"
              suffix="min"
              background-color="white"
              label="Tiempo de preparación"
              placeholder
              @change="updateRecipe()"
              outlined
            ></v-text-field>
          </v-flex>

          <v-flex xs12>
            <v-autocomplete
              v-model="recipe.types"
              multiple
              outlined
              chips
              label="Tipo de receta"
              :items="tipoReceta"
              item-text="label"
              item-value="value"
              @change="updateRecipe()"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12>
            <v-select
              v-model="recipe.vegan"
              :items="[
                { text: 'Vegana', value: true },
                { text: 'Flexible', value: false },
              ]"
              item-text="text"
              item-value="value"
              label="Tipo de dieta"
              outlined
            ></v-select>
          </v-flex>

          <v-flex xs12>
            <v-file-input
              outlined
              label="Añadir imagen"
              @change="onFilePicked"
            ></v-file-input>
          </v-flex>
        </v-col>
      </v-row>

      <div class="title primary--text my-4">Ingredientes</div>

      <div class="d-flex align-center justify-space-between my-6">
        <div class="title" style="width: 180px">Preparación previa</div>
        <div class="d-flex align-center justify-end">
          <v-btn
            class="ml-2"
            small
            color="primary"
            @click="addGroupIngredients('PREVIOUS_PREPARATION')"
            >Añadir grupo de ingredientes</v-btn
          >
          <v-btn
            class="ml-2"
            small
            color="primary"
            @click="addIngredient('PREVIOUS_PREPARATION')"
            >Añadir ingrediente</v-btn
          >
        </div>
      </div>

      <v-flex xs12 class="mb-4">
        <v-toolbar color="secondary" dark flat dense>
          <v-row>
            <v-col cols="12" sm="1">
              <div class="caption"></div>
            </v-col>
            <v-col cols="12" sm="1" class="text-center">
              <div class="caption">Nombre</div>
            </v-col>
            <v-col cols="12" sm="1" class="text-center">
              <div class="caption">Grupo</div>
            </v-col>
            <v-col cols="12" sm="1" class="text-center">
              <div class="caption">Cantidad</div>
            </v-col>
            <v-col cols="12" sm="1" class="text-center">
              <div class="caption">Peso</div>
            </v-col>
            <v-col cols="12" sm="1" class="text-center">
              <div class="caption">Alérgeno</div>
            </v-col>
            <v-col cols="12" sm="1" class="text-center">
              <div class="caption">Alternativa</div>
            </v-col>
            <v-col cols="12" sm="1" class="text-center">
              <div
                class="caption"
                title="ingrediente incluido en lista de la compra"
                >I.I.L.C</div
              >
            </v-col>
            <v-col cols="12" sm="1" class="text-center">
              <div
                class="caption"
                title="alternativa incluida en lista de la compra"
                >A.I.L.C</div
              >
            </v-col>
          </v-row>
        </v-toolbar>
        <StepsList
          :steps="ingredientsPreparacion"
          :type="'ingredients'"
          @move="moveStep($event, 'ingredients', 'preparacion')"
          @remove="removeStep($event, 'ingredients', 'preparacion')"
          @edit="editIngredient($event, 'PREVIOUS_PREPARATION')"
        ></StepsList>
      </v-flex>

      <div class="d-flex align-center justify-space-between my-6">
        <div class="title" style="width: 180px">Elaboración</div>
        <div class="d-flex align-center justify-end">
          <v-btn
            class="ml-2"
            small
            color="primary"
            @click="addGroupIngredients('MAIN_PREPARATION')"
            >Añadir grupo de ingredientes</v-btn
          >
          <v-btn
            class="ml-2"
            small
            color="primary"
            @click="addIngredient('MAIN_PREPARATION')"
            >Añadir ingrediente</v-btn
          >
        </div>
      </div>

      <v-flex xs12 class="mb-4">
        <v-toolbar color="secondary" dark flat dense>
          <v-row>
            <v-col cols="12" sm="1">
              <div class="caption"></div>
            </v-col>
            <v-col cols="12" sm="1" class="text-center">
              <div class="caption">Nombre</div>
            </v-col>
            <v-col cols="12" sm="1" class="text-center">
              <div class="caption">Grupo</div>
            </v-col>
            <v-col cols="12" sm="1" class="text-center">
              <div class="caption">Cantidad</div>
            </v-col>
            <v-col cols="12" sm="1" class="text-center">
              <div class="caption">Peso</div>
            </v-col>
            <v-col cols="12" sm="1" class="text-center">
              <div class="caption">Alérgeno</div>
            </v-col>
            <v-col cols="12" sm="1" class="text-center">
              <div class="caption">Alternativa</div>
            </v-col>
            <v-col cols="12" sm="1" class="text-center">
              <div
                class="caption"
                title="ingrediente incluido en lista de la compra"
                >I.I.L.C</div
              >
            </v-col>
            <v-col cols="12" sm="1" class="text-center">
              <div
                class="caption"
                title="alternativa incluida en lista de la compra"
                >A.I.L.C</div
              >
            </v-col>
          </v-row>
        </v-toolbar>
        <StepsList
          :steps="ingredientsElaboracion"
          :type="'ingredients'"
          @move="moveStep($event, 'ingredients', 'MAIN_PREPARATION')"
          @remove="removeStep($event, 'ingredients', 'MAIN_PREPARATION')"
          @edit="editIngredient($event, 'MAIN_PREPARATION')"
        ></StepsList>
      </v-flex>

      <div class="title primary--text my-4">Pasos</div>

      <div class="d-flex justify-space-between align-center my-6">
        <div class="title">Preparación</div>
        <v-btn
          small
          class="ml-2"
          color="primary"
          @click="addStep('PREVIOUS_PREPARATION')"
          >Añadir paso</v-btn
        >
      </div>

      <v-flex xs12 class="mb-4">
        <v-toolbar color="secondary" dark flat dense>
          <v-row>
            <v-col cols="12" sm="1">
              <div class="body-1">Paso</div>
            </v-col>
            <v-col cols="12" sm="2">
              <div class="body-1">Preparación</div>
            </v-col>
            <v-col cols="12" sm="2">
              <div class="body-1">Ingredientes</div>
            </v-col>
            <v-col cols="12" sm="1">
              <div class="body-1">Cantidad</div>
            </v-col>
            <v-col cols="12" sm="6">
              <div class="body-1">Descripción</div>
            </v-col>
          </v-row>
        </v-toolbar>
        <StepsList
          :steps="stepsPreparacion"
          :type="'steps'"
          @move="moveStep($event, 'steps', 'PREVIOUS_PREPARATION')"
          @remove="removeStep($event, 'steps', 'PREVIOUS_PREPARATION')"
          @edit="editStep($event, 'steps', 'PREVIOUS_PREPARATION')"
        ></StepsList>
      </v-flex>

      <div class="d-flex justify-space-between align-center my-6">
        <div class="title">Elaboración</div>
        <v-btn
          small
          class="ml-2"
          color="primary"
          @click="addStep('MAIN_PREPARATION')"
          >Añadir paso</v-btn
        >
      </div>

      <v-flex xs12 class="mb-4">
        <v-toolbar color="secondary" dark flat dense>
          <v-row>
            <v-col cols="12" sm="1">
              <div class="body-1">Paso</div>
            </v-col>
            <v-col cols="12" sm="2">
              <div class="body-1">Preparación</div>
            </v-col>
            <v-col cols="12" sm="2">
              <div class="body-1">Ingredientes</div>
            </v-col>
            <v-col cols="12" sm="1">
              <div class="body-1">Cantidad</div>
            </v-col>
            <v-col cols="12" sm="6">
              <div class="body-1">Descripción</div>
            </v-col>
          </v-row>
        </v-toolbar>
        <StepsList
          :steps="stepsElaboracion"
          :type="'steps'"
          @move="moveStep($event, 'steps', 'MAIN_PREPARATION')"
          @remove="removeStep($event, 'steps', 'MAIN_PREPARATION')"
          @edit="editStep($event, 'steps', 'MAIN_PREPARATION')"
        ></StepsList>
      </v-flex>

      <div class="title primary--text my-4">Simplificación de la receta</div>

      <v-flex>
        <v-textarea
          v-model="recipe.simplification"
          outlined
          name="input-7-4"
          label="Simplificación de la receta"
          @change="updateRecipe()"
        ></v-textarea>
      </v-flex>

      <v-flex xs12>
        <v-switch
          color="primary"
          v-model="recipe.published"
          class="ma-2"
          label="Visible en el recetario"
          @change="updateRecipe()"
        ></v-switch>
      </v-flex>

      <v-btn class="text-white" color="secondary" to="/recetas">
        <v-icon left dark>mdi-arrow-left</v-icon>Volver
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import store from "../../store";
import {
  RECIPE_TYPE,
  UNITS_OF_MEASURE,
  VARIANT,
  STEP_TYPE,
} from "../../constants";
import StepsList from "@/components/stepsList";
import { getRecipe } from "../../services/recipes";
import recipeMapper from "../../mapper/recipe";
import stepsMapper from "../../mapper/recipe_step";
import ingredientMapper from "../../mapper/recipe_ingredient";

export default {
  data() {
    return {
      recipe: {
        title: null,
        preparationTime: null,
        types: [],
        published: false,
        authorId: null,
        imageUrl: null,
        simplification: null,
        vegan: true,
      },
      dialogIngredient: false,
      dialogGroupIngredient: false,
      dialogStep: false,
      tipoReceta: RECIPE_TYPE,
      unidadMedida: UNITS_OF_MEASURE.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      ),
      tipoPaso: STEP_TYPE,
      varianteIng: VARIANT,
      implies_recipe_modification: false,
      starred: false,
      stepTypeCreation: null,
      step: {
        step: null,
        type: null,
        title: null,
        description: null,
        alternative: [],
      },
      ingredientTypeCreation: null,
      stepsPreparacion: [],
      stepsElaboracion: [],
      ingredient: {
        ingredientId: null,
        id: null,
        qty: null,
        units: null,
        variant: null,
        starred: false,
        category: null,
        impliesRecipeModification: false,
        alternativeId: null,
        alternative: {
          id: null,
          qty: null,
          units: null,
          variant: null,
        },
      },
      groupIngredient: {
        id: null,
        name: "",
        ingredients: [],
      },
      creationId: null,
      ingredientsPreparacion: [],
      ingredientsElaboracion: [],
      isErrorModal: false,
      errorMessage: null,
      updatedSuccess: false,
      selectedIngredientContainsAllergen: false,
      selectedAllergen: null,
      ingredientType: "SIMPLE",
      ingredientAlternativeType: "SIMPLE",
      stepMode: "create",
      hasErrorIngredient: false,
      hasErrorGroupIngredient: false,
      hasErrorStep: false,
    };
  },
  components: { StepsList },
  computed: {
    ingredients$() {
      return store.getters["ingredients/allItems"].map((ingredient) => ({
        id: ingredient.id,
        name: ingredient.name,
        allergenId: ingredient.allergen_id,
        type: ingredient.type,
        include_in_shopping_list: !!ingredient.include_in_shopping_list,
        group: ingredient.group,
        vegan: ingredient.vegan,
      }));
    },
    groupIngredients$() {
      return store.getters["ingredients/groupItems"];
    },
    allergens$() {
      return store.getters["ingredients/allergens"];
    },
    error$() {
      return store.getters["recipes/error"];
    },
  },
  async mounted() {
    const { data } = await getRecipe(this.$route.params.id);
    const recipeMapped = recipeMapper(data, "toUI");
    this.recipe = { ...recipeMapped };

    this.ingredientsPreparacion = (recipeMapped.ingredients || [])
      .filter((ingredient) => ingredient.type === "PREVIOUS_PREPARATION")
      .map((ingredient) => {
        let customIngredient = ingredientMapper({
          ...ingredient,
          id: ingredient.recipes_ingredients_id,
          ingredientId: ingredient.ingredient_id,
          title: this.getSimpleIngredientNameById(ingredient.ingredient_id)
            .name,
          impliesRecipeModification: ingredient.implies_recipe_modification,
          typeIngredient: ingredient.ingredient_type,
          alternative:
            ingredient.allergen_id && ingredient.alternative
              ? {
                  title: ingredient.alternative.ingredient_name,
                  type: ingredient.alternative.type,
                  allergen: ingredient.allergen_name,
                  ingredientId: ingredient.alternative.ingredient_id,
                  qty: ingredient.alternative.qty,
                  units: ingredient.alternative.units_of_measure,
                  variant: ingredient.alternative.variant,
                  recipes_ingredients_id:
                    ingredient.alternative.recipes_ingredients_id,
                  include_in_shopping_list:
                    ingredient.alternative.include_in_shopping_list,
                }
              : null,
        });
        return customIngredient;
      })
      .sort((a, b) => {
        return a.order - b.order;
      });

    this.ingredientsElaboracion = (recipeMapped.ingredients || [])
      .filter((ingredient) => ingredient.type === "MAIN_PREPARATION")
      .map((ingredient) => {
        let customIngredient = ingredientMapper({
          ...ingredient,
          id: ingredient.recipes_ingredients_id,
          ingredientId: ingredient.ingredient_id,
          title: this.getSimpleIngredientNameById(ingredient.ingredient_id)
            .name,
          impliesRecipeModification: ingredient.implies_recipe_modification,
          typeIngredient: ingredient.ingredient_type,
          alternative:
            ingredient.allergen_id && ingredient.alternative
              ? {
                  title: ingredient.alternative.ingredient_name,
                  type: ingredient.alternative.type,
                  allergen: ingredient.allergen_name,
                  ingredientId: ingredient.alternative.ingredient_id,
                  qty: ingredient.alternative.qty,
                  units: ingredient.alternative.units_of_measure,
                  variant: ingredient.alternative.variant,
                  recipes_ingredients_id:
                    ingredient.alternative.recipes_ingredients_id,
                  include_in_shopping_list:
                    ingredient.alternative.include_in_shopping_list,
                }
              : null,
        });
        return customIngredient;
      })
      .sort((a, b) => {
        return a.order - b.order;
      });

    this.stepsPreparacion = (recipeMapped.steps || [])
      .filter((step) => step.type === "PREVIOUS_PREPARATION")
      .map((step) =>
        stepsMapper({
          ...step,
          id: step.recipe_step_id,
          alternative: [
            ...(step.step_ingredients || []).map((stepIngredient) => ({
              ...stepIngredient,
              ingredientId: stepIngredient.ingredient_id,
              title: stepIngredient.ingredient_name, // this.getSimpleIngredientNameById(stepIngredient.recipes_ingredients_id).name,
              qty: this.getIngredientQtyUnitsFromStep(
                stepIngredient.recipes_ingredients_id,
                step.type
              ).qty,
              units: this.getIngredientQtyUnitsFromStep(
                stepIngredient.recipes_ingredients_id,
                step.type
              ).units,
            })),
          ],
        })
      )
      .sort((a, b) => {
        return a.order - b.order;
      });

    this.stepsElaboracion = (recipeMapped.steps || [])
      .filter((step) => step.type === "MAIN_PREPARATION")
      .map((step) =>
        stepsMapper({
          ...step,
          id: step.recipe_step_id,
          alternative: [
            ...(step.step_ingredients || []).map((stepIngredient) => ({
              ...stepIngredient,
              ingredientId: stepIngredient.ingredient_id,
              title: stepIngredient.ingredient_name, // this.getSimpleIngredientNameById(stepIngredient.recipes_ingredients_id).name,
              qty: this.getIngredientQtyUnitsFromStep(
                stepIngredient.recipes_ingredients_id,
                step.type
              ).qty,
              units: this.getIngredientQtyUnitsFromStep(
                stepIngredient.recipes_ingredients_id,
                step.type
              ).units,
            })),
          ],
        })
      )
      .sort((a, b) => {
        return a.order - b.order;
      });
  },
  methods: {
    ingredientsWithoutAllergen(id) {
      const ingredientAllergenId = id || this.selectedAllergen.id;
      return this.ingredients$
        .filter((ingredient) => ingredient.allergenId !== ingredientAllergenId)
        .map((ingredient) => ({
          id: ingredient.id,
          name: ingredient.name,
          allergenId: ingredient.allergen_id,
          type: ingredient.type,
          include_in_shopping_list: !!ingredient.include_in_shopping_list,
          group: ingredient.group,
        }));
    },
    selectedGroupIngredients(evt) {
      const groupSelected = this.groupIngredients$.find(
        (group) => group.id === evt
      );
      if (groupSelected) {
        this.groupIngredient.ingredients = groupSelected.sub_ingredients;
      }

      this.groupIngredient.ingredients.map((ingredient) => {
        if (ingredient.allergen_id) {
          ingredient.alternative = {};
        }
        ingredient.ingredientId = ingredient.id;
        ingredient.group = groupSelected.name;
        return ingredient;
      });
    },
    addGroupIngredients(type) {
      this.groupIngredient = {
        id: null,
        name: "",
        ingredients: [],
      };
      this.ingredientTypeCreation = type;
      this.dialogGroupIngredient = true;
    },
    createGroupIngredient() {
      this.hasErrorGroupIngredient = false;

      const incompletedAllergen = this.groupIngredient.ingredients.some(
        (ingredient) => {
          if (ingredient.allergen_id) {
            return (
              !ingredient.alternative.ingredientId ||
              !ingredient.alternative.qty ||
              !ingredient.alternative.units
            );
          }
          return false;
        }
      );

      if (!incompletedAllergen) {
        this.groupIngredient.ingredients.forEach(async (ingredient, index) => {
          await this.createIngredient(ingredient, index);
        });
        this.dialogGroupIngredient = false;
      } else {
        this.hasErrorGroupIngredient = true;
      }
    },
    onFilePicked(evt) {
      this.$store
        .dispatch("recipes/UploadImage", { image: evt })
        .then((url) => {
          this.recipe.imageUrl = url;
          this.updateRecipe();
        })
        .catch(() => {
          alert("No ha sido posible subir la imagen.");
        });
    },
    addIngredient(type) {
      this.stepMode = "create";

      // reset values
      this.ingredient = {
        id: null,
        qty: null,
        units: null,
        variant: null,
        alternativeId: null,
        starred: false,
        impliesRecipeModification: false,
        vegan: this.recipe.vegan,
        alternative: {
          id: null,
          qty: null,
          type: null,
          units: null,
          variant: null,
        },
      };

      this.ingredientType = "SIMPLE";
      this.ingredientAlternativeType = "SIMPLE";
      this.selectedIngredientContainsAllergen = false;
      this.selectedAllergen = null;
      this.ingredientTypeCreation = type;
      this.dialogIngredient = true;
    },
    addStep(type) {
      this.stepMode = "create";

      // reset values for ingredients step
      this.step.title = null;
      this.step.step = null;
      this.step.description = null;
      if (type === "PREVIOUS_PREPARATION") {
        this.step.alternative = [
          ...this.ingredientsPreparacion.map(() => ({
            active: false,
            description: null,
          })),
        ];
      } else {
        this.step.alternative = [
          ...this.ingredientsElaboracion.map(() => ({
            active: false,
            description: null,
          })),
          ...this.ingredientsPreparacion.map(() => ({
            active: false,
            description: null,
          })),
        ];
      }

      this.stepTypeCreation = type;
      this.dialogStep = true;
    },
    async createStep() {
      const newStep = {
        ...this.step,
        type: this.stepTypeCreation,
        alternative: [
          ...this.step.alternative
            .filter((alternativeIngredient) => alternativeIngredient.active)
            .map((ingredient) => {
              const ingredientId = ingredient.ingredientId
                ? ingredient.ingredientId
                : ingredient.id;
              return {
                ...ingredient,
                title: this.getSimpleIngredientNameById(ingredientId).name,
                qty: this.getIngredientQtyUnitsFromStep(
                  ingredient.id,
                  this.stepTypeCreation
                ).qty,
                units: this.getIngredientQtyUnitsFromStep(
                  ingredient.id,
                  this.stepTypeCreation
                ).units,
              };
            }),
        ],
      };

      if (!newStep.title || !newStep.description || !newStep.step) {
        this.hasErrorStep = true;
        return;
      }
      this.hasErrorStep = false;

      if (this.stepMode === "create") {
        let stepOrder = 0;
        if (this.stepTypeCreation === "PREVIOUS_PREPARATION") {
          stepOrder = this.stepsPreparacion.length;
        } else {
          stepOrder = this.stepsElaboracion.length;
        }

        newStep.order = stepOrder;

        await this.dispatchNewStep(
          this.recipe.id,
          stepOrder,
          newStep,
          this.stepTypeCreation === "PREVIOUS_PREPARATION"
            ? this.ingredientsPreparacion
            : this.ingredientsElaboracion
        );

        // TODO: Add this as a side effect
        if (newStep.type === "PREVIOUS_PREPARATION") {
          this.stepsPreparacion.push({ ...newStep, id: this.creationId });
        } else {
          this.stepsElaboracion.push({ ...newStep, id: this.creationId });
        }
        this.creationId = null;
      } else if (this.stepMode === "edit") {
        this.dispatchUpdateStep(this.recipe.id, newStep);

        if (newStep.type === "PREVIOUS_PREPARATION") {
          this.stepsPreparacion = this.stepsPreparacion.map((step) => {
            if (step.id === newStep.id) {
              return {
                ...newStep,
              };
            }

            return step;
          });
        } else {
          this.stepsElaboracion = this.stepsElaboracion.map((step) => {
            if (step.id === newStep.id) {
              return {
                ...newStep,
              };
            }

            return step;
          });
        }
      }

      this.dialogStep = false;
      this.stepTypeCreation = null;
    },
    dispatchNewStep(recipeId, order, step, ingredients) {
      recipeId = recipeId ? recipeId : this.recipe.id;
      return this.$store
        .dispatch("recipes/CreateNewStep", {
          recipeId,
          order,
          step,
          ingredients,
        })
        .then((id) => {
          this.creationId = id;
          this.updatedSuccess = true;
          setTimeout(() => {
            this.updatedSuccess = false;
          }, 2000);
        });
    },
    dispatchUpdateStep(recipeId, step) {
      recipeId = recipeId ? recipeId : this.recipe.id;
      const updateStep = {
        ...step,
        recipeId,
      };
      this.$store
        .dispatch("recipes/UpdateStep", {
          recipeId,
          step: updateStep,
        })
        .then(() => {
          this.updatedSuccess = true;
          setTimeout(() => {
            this.updatedSuccess = false;
          }, 2000);
        });
    },
    dispatchUpdateIngredient(ingredientId, ingredient) {
      const updateIngredient = {
        ...ingredient,
      };
      this.$store
        .dispatch("recipes/UpdateIngredient", {
          ingredientId,
          recipeId: this.recipe.id,
          ingredient: updateIngredient,
        })
        .then(() => {
          this.updatedSuccess = true;
          setTimeout(() => {
            this.updatedSuccess = false;
          }, 2000);
        });
    },
    dispatchUpdateStepOrder(recipeId, step) {
      recipeId = recipeId ? recipeId : this.recipe.id;
      const updateStep = {
        ...step,
      };
      this.$store
        .dispatch("recipes/UpdateStepOrder", {
          recipeId,
          step: updateStep,
        })
        .then(() => {
          this.updatedSuccess = true;
          setTimeout(() => {
            this.updatedSuccess = false;
          }, 2000);
        });
    },
    dispatchRemoveStep(stepId) {
      this.$store.dispatch("recipes/DeleteStep", { stepId }).then(() => {
        this.updatedSuccess = true;
        setTimeout(() => {
          this.updatedSuccess = false;
        }, 2000);
      });
    },
    dispatchNewRecipeIngredient(recipeId, order, ingredient) {
      recipeId = recipeId ? recipeId : this.recipe.id;
      return this.$store
        .dispatch("recipes/CreateNewRecipeIngredient", {
          recipeId,
          order,
          ingredient,
        })
        .then((id) => {
          this.creationId = id;
        });
    },
    dispatchUpdateRecipeIngredient(recipeId, ingredient) {
      recipeId = recipeId ? recipeId : this.recipe.id;
      const updateIngredient = {
        ...ingredient,
      };
      this.$store
        .dispatch("recipes/UpdateRecipeIngredient", {
          recipeId,
          ingredient: updateIngredient,
        })
        .then(() => {
          this.updatedSuccess = true;
          setTimeout(() => {
            this.updatedSuccess = false;
          }, 2000);
        });
    },
    dispatchRemoveRecipeIngredient(recipeIngredientId) {
      this.$store
        .dispatch("recipes/DeleteRecipeIngredient", {
          recipeIngredientId,
        })
        .then(() => {
          this.updatedSuccess = true;
          setTimeout(() => {
            this.updatedSuccess = false;
          }, 2000);
        });
    },
    async createIngredient(ingredient, index) {
      const ingredientParameter = ingredient || this.ingredient;

      const {
        name,
        include_in_shopping_list,
        type,
        allergenId,
      } = this.ingredients$.find(
        (ingredient) => ingredientParameter.ingredientId === ingredient.id
      );

      const allergenName = this.getAllergenById(allergenId)
        ? this.getAllergenById(allergenId).name
        : null;

      let alternativeIngredient;

      if (
        ingredientParameter.alternative &&
        ingredientParameter.alternative.ingredientId
      ) {
        const {
          name: alternativeName,
          include_in_shopping_list: alternative_include_in_shopping_list,
        } = this.ingredients$.find(
          (ingredient) =>
            ingredientParameter.alternative.ingredientId === ingredient.id
        );

        alternativeIngredient = {
          ...ingredientParameter.alternative,
          type: this.ingredientTypeCreation,
          title: alternativeName,
          include_in_shopping_list: alternative_include_in_shopping_list,
          allergen: allergenName,
          group: ingredientParameter.group,
        };
      }

      if (!ingredientParameter.qty || !ingredientParameter.units) {
        this.hasErrorIngredient = true;
        return;
      }

      this.hasErrorIngredient = false;

      const newIngredient = {
        ...ingredientParameter,
        type: this.ingredientTypeCreation,
        title: name,
        include_in_shopping_list,
        typeIngredient: type,
        alternative: alternativeIngredient,
        vegan: this.recipe.vegan,
      };

      if (this.stepMode === "create") {
        if (newIngredient.type === "PREVIOUS_PREPARATION") {
          newIngredient.order = index
            ? this.ingredientsPreparacion.length + index
            : this.ingredientsPreparacion.length;

          if (newIngredient.alternative) {
            newIngredient.alternative.order = newIngredient.order;
          }

          await this.dispatchNewRecipeIngredient(
            this.recipe.id,
            newIngredient.order,
            newIngredient
          );
          const newIngredientWithId = {
            ...newIngredient,
            id: parseInt(this.creationId, 10),
            recipes_ingredients_id: parseInt(this.creationId, 10),
          };

          this.creationId = null;

          this.ingredientsPreparacion.push(newIngredientWithId);
          this.ingredientsPreparacion.sort((a, b) => a.order - b.order);
        } else {
          newIngredient.order = index
            ? this.ingredientsElaboracion.length + index
            : this.ingredientsElaboracion.length;

          if (newIngredient.alternative) {
            newIngredient.alternative.order = newIngredient.order;
          }

          await this.dispatchNewRecipeIngredient(
            this.recipe.id,
            newIngredient.order,
            newIngredient
          );

          const newIngredientWithId = {
            ...newIngredient,
            id: parseInt(this.creationId, 10),
            recipes_ingredients_id: parseInt(this.creationId, 10),
          };

          this.creationId = null;

          this.ingredientsElaboracion.push(newIngredientWithId);
          this.ingredientsElaboracion.sort((a, b) => a.order - b.order);
        }

        // add new entry to steps alternative
        this.step.alternative.push({ active: false, description: null });
      } else if (this.stepMode === "edit") {
        if (newIngredient.type === "PREVIOUS_PREPARATION") {
          newIngredient.order = this.ingredientsPreparacion.findIndex(
            (value) => value.id === newIngredient.id
          );
        } else {
          newIngredient.order = this.ingredientsElaboracion.findIndex(
            (value) => value.id === newIngredient.id
          );
        }

        if (newIngredient.alternative) {
          newIngredient.alternative.order = newIngredient.order;
        }

        this.dispatchUpdateIngredient(this.ingredient.id, newIngredient);

        if (newIngredient.type === "PREVIOUS_PREPARATION") {
          this.ingredientsPreparacion = this.ingredientsPreparacion.map(
            (ingredient) => {
              if (ingredient.id === newIngredient.id) {
                return { ...newIngredient };
              }

              return ingredient;
            }
          );
        } else {
          this.ingredientsElaboracion = this.ingredientsElaboracion.map(
            (ingredient) => {
              if (ingredient.id === newIngredient.id) {
                return { ...newIngredient };
              }

              return ingredient;
            }
          );
        }
      }

      this.ingredient = {
        id: null,
        qty: null,
        units: null,
        variant: null,
        alternativeId: null,
        starred: false,
        impliesRecipeModification: false,
        alternative: {
          id: null,
          qty: null,
          units: null,
          variant: null,
          type: null,
        },
      };
      this.dialogIngredient = false;
      this.ingredientTypeCreation = null;
      this.selectedIngredientContainsAllergen = false;
      this.ingredientType = "SIMPLE";
      this.selectedAllergen = null;
    },
    menu() {
      store.dispatch("user/ToggleMenu");
    },
    updateRecipe() {
      this.recipe.authorId = store.getters["user/currentUser"].id;

      this.$store
        .dispatch("recipes/UpdateRecipe", {
          recipe: this.recipe,
          steps: [...this.stepsPreparacion, ...this.stepsElaboracion],
          ingredients: [
            ...this.ingredientsPreparacion,
            ...this.ingredientsElaboracion,
          ],
        })
        .then(() => {
          this.updatedSuccess = true;
          setTimeout(() => {
            this.updatedSuccess = false;
          }, 2000);
        })
        .catch(() => {
          alert("No ha sido posible guardar la receta.");
        });
    },
    moveStep(evt, category, type) {
      let steps;
      if (type === "MAIN_PREPARATION") {
        steps =
          category === "steps"
            ? this.stepsElaboracion
            : this.ingredientsElaboracion;
      } else {
        steps =
          category === "steps"
            ? this.stepsPreparacion
            : this.ingredientsPreparacion;
      }

      const { direction, index } = evt;
      const originalIndex = index;
      const step = steps[index];
      steps.splice(index, 1);

      if (direction === "up") {
        const newIndex = index - 1;
        steps.splice(newIndex, 0, step);
      } else {
        const newIndex = index + 1;
        steps.splice(newIndex, 0, step);
      }

      steps.map((step, index) => {
        step.order = index;
        return step;
      });

      if (category === "steps") {
        this.dispatchUpdateStepOrder(this.recipe.id, step);

        // side effect
        steps[originalIndex].order = originalIndex;
        this.dispatchUpdateStepOrder(this.recipe.id, steps[originalIndex]);
      } else {
        this.dispatchUpdateRecipeIngredient(this.recipe.id, step);

        // side effect
        steps[originalIndex].order = originalIndex;
        this.dispatchUpdateRecipeIngredient(
          this.recipe.id,
          steps[originalIndex]
        );
      }
    },
    editIngredient(evt, type) {
      this.stepMode = "edit";
      const { index } = evt;

      if (type === "PREVIOUS_PREPARATION") {
        const variantIngredient = (
          this.ingredientsPreparacion[index].variant || ""
        ).toLowerCase();

        let alternativeIngredient = {};

        if (
          this.ingredientsPreparacion[index].allergen_id &&
          this.ingredientsPreparacion[index].alternative
        ) {
          alternativeIngredient = this.ingredientsPreparacion[index]
            .alternative;

          if (this.ingredientsPreparacion[index].alternative.variant) {
            alternativeIngredient.variant = (
              this.ingredientsPreparacion[index].alternative.variant || ""
            ).toLowerCase();
          }
        }

        this.ingredient = {
          ...this.ingredientsPreparacion[index],
          variant: variantIngredient,
          alternative: alternativeIngredient,
          impliesRecipeModification: this.ingredientsPreparacion[index]
            .impliesRecipeModification,
        };

        this.selectedIngredient(
          this.ingredientsPreparacion[index].ingredientId
        );
      } else {
        const variantIngredient = (
          this.ingredientsElaboracion[index].variant || ""
        ).toLowerCase();

        let alternativeIngredient = {};

        if (
          this.ingredientsElaboracion[index].allergen_id &&
          this.ingredientsElaboracion[index].alternative
        ) {
          alternativeIngredient = this.ingredientsElaboracion[index]
            .alternative;

          if (this.ingredientsElaboracion[index].alternative.variant) {
            alternativeIngredient.variant = (
              this.ingredientsElaboracion[index].alternative.variant || ""
            ).toLowerCase();
          }
        }

        this.ingredient = {
          ...this.ingredientsElaboracion[index],
          variant: variantIngredient,
          impliesRecipeModification: this.ingredientsElaboracion[index]
            .impliesRecipeModification,
          alternative: alternativeIngredient,
        };

        this.selectedIngredient(
          this.ingredientsElaboracion[index].ingredientId
        );
      }

      this.ingredientTypeCreation = type;
      this.dialogIngredient = true;
    },
    editStep(evt, category, type) {
      this.stepMode = "edit";
      const { index } = evt;
      let step;
      let ingredients = [];

      if (type === "PREVIOUS_PREPARATION") {
        step = this.stepsPreparacion[index];
        ingredients = [...this.ingredientsPreparacion];
      } else {
        step = this.stepsElaboracion[index];
        ingredients = [
          ...this.ingredientsPreparacion,
          ...this.ingredientsElaboracion,
        ];
      }

      this.step.alternative = [
        ...ingredients.map((i) => {
          const alternative = step.alternative.find(
            (al) => al.ingredientId === i.ingredientId
          );
          return {
            ...i,
            active: !!alternative,
            description: alternative
              ? alternative.description || alternative.alternative_description
              : "",
          };
        }),
      ];

      this.step.id = step.id;
      this.step.title = step.title;
      this.step.step =
        (STEP_TYPE.filter((type) => type.label === step.step)[0] || {}).value ||
        step.step;
      this.step.description = step.description;
      this.step.order = index;

      this.stepTypeCreation = type;
      this.dialogStep = true;
    },
    removeStep(evt, category, type) {
      if (type === "MAIN_PREPARATION") {
        if (category === "steps") {
          this.dispatchRemoveStep(this.stepsElaboracion[evt.index].id);
          this.stepsElaboracion.splice(evt.index, 1);
        } else {
          this.dispatchRemoveRecipeIngredient(
            this.ingredientsElaboracion[evt.index].recipes_ingredients_id
          );

          this.ingredientsElaboracion.splice(evt.index, 1);
        }
      } else {
        if (category === "steps") {
          this.dispatchRemoveStep(this.stepsPreparacion[evt.index].id);
          this.stepsPreparacion.splice(evt.index, 1);
        } else {
          this.dispatchRemoveRecipeIngredient(
            this.ingredientsPreparacion[evt.index].recipes_ingredients_id
          );

          this.ingredientsPreparacion.splice(evt.index, 1);
        }
      }
    },
    selectedIngredient(evt) {
      const ingredientSelected = this.ingredients$.find(
        (ingredient) => ingredient.id === evt
      );

      this.hasErrorIngredient = false;

      this.ingredientType = ingredientSelected.type;

      if (ingredientSelected && ingredientSelected.allergenId) {
        this.selectedIngredientContainsAllergen = true;
        this.selectedAllergen = this.allergens$.find(
          (allergen) => allergen.id === ingredientSelected.allergenId
        );
      } else {
        this.selectedIngredientContainsAllergen = false;
      }
    },

    selectedAlternativeIngredient(evt) {
      const ingredientSelected = this.ingredients$.find(
        (ingredient) => ingredient.id === evt
      );
      this.ingredientAlternativeType = ingredientSelected.type;
    },

    getSimpleIngredientNameById(id) {
      const ingredient = this.ingredients$.find(
        (ingredient) => ingredient.id === id
      );
      return {
        name: ingredient ? ingredient.name : "",
        type: ingredient ? ingredient.type : "",
      };
    },
    selectIngredientId(ingredient, index) {
      this.isErrorModal = false;
      this.errorMessage = "";

      this.step.alternative[index].id = ingredient.id;
      this.step.alternative[index].ingredientId = ingredient.ingredientId;
    },
    getIngredientQtyUnitsFromStep(stepIngredientId, type) {
      let ingredients;
      if (type === "PREVIOUS_PREPARATION") {
        ingredients = [...this.ingredientsPreparacion];
      } else {
        ingredients = [
          ...this.ingredientsPreparacion,
          ...this.ingredientsElaboracion,
        ];
      }
      const ingredientStep = ingredients.filter(
        (ingredient) => ingredient.id === stepIngredientId
      )[0];
      return ingredientStep
        ? { qty: ingredientStep.qty, units: ingredientStep.units }
        : { qty: null, units: null };
    },
    getAllergenById(id) {
      return this.allergens$.find((allergen) => allergen.id === id);
    },
  },
};
</script>

<style></style>
