var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "caption" },
    [
      _c(
        "v-app-bar",
        { attrs: { flat: "", app: "", color: "white", light: "" } },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.menu($event)
              }
            }
          }),
          _c(
            "div",
            { staticClass: "pr-2 pl-2" },
            [
              _c("v-icon", { attrs: { color: "primary" } }, [
                _vm._v("mdi-ballot")
              ])
            ],
            1
          ),
          _c("v-toolbar-title", [
            _c(
              "span",
              { staticStyle: { color: "#84264f", "font-weight": "bold" } },
              [_vm._v("Recetas")]
            ),
            _vm._v(" / Editar\n      receta\n    ")
          ])
        ],
        1
      ),
      _vm.error$.isError
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v(_vm._s(_vm.error$.message))
          ])
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          model: {
            value: _vm.updatedSuccess,
            callback: function($$v) {
              _vm.updatedSuccess = $$v
            },
            expression: "updatedSuccess"
          }
        },
        [
          _vm._v("\n    Receta guardada correctamente\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", text: "" },
              on: {
                click: function($event) {
                  _vm.updatedSuccess = false
                }
              }
            },
            [_vm._v("Cerrar")]
          )
        ],
        1
      ),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "600px" },
              model: {
                value: _vm.dialogGroupIngredient,
                callback: function($$v) {
                  _vm.dialogGroupIngredient = $$v
                },
                expression: "dialogGroupIngredient"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", color: "primary", flat: "" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v("Añadir Grupo de ingredientes")
                      ]),
                      _c("div", { staticClass: "flex-grow-1" }),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              _vm.dialogGroupIngredient = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _vm.hasErrorGroupIngredient
                                    ? _c(
                                        "v-alert",
                                        { attrs: { type: "error" } },
                                        [
                                          _vm._v(
                                            "Error añadiendo el grupo de ingredientes."
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      label: "Nombre del grupo de ingredientes",
                                      items: _vm.groupIngredients$,
                                      "item-text": "name",
                                      "item-value": "id",
                                      outlined: ""
                                    },
                                    on: {
                                      change: _vm.selectedGroupIngredients
                                    },
                                    model: {
                                      value: _vm.groupIngredient.id,
                                      callback: function($$v) {
                                        _vm.$set(_vm.groupIngredient, "id", $$v)
                                      },
                                      expression: "groupIngredient.id"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._l(_vm.groupIngredient.ingredients, function(
                            ingredient,
                            index
                          ) {
                            return _c(
                              "v-row",
                              { key: index },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "title",
                                    attrs: { cols: "12" }
                                  },
                                  [_vm._v(_vm._s(ingredient.name))]
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", sm: "6", md: "4" }
                                      },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            dense: "",
                                            type: "number",
                                            outlined: "",
                                            label: "Cantidad",
                                            required: "",
                                            hint: "Para 4 personas",
                                            "persistent-hint": ""
                                          },
                                          model: {
                                            value: ingredient.qty,
                                            callback: function($$v) {
                                              _vm.$set(ingredient, "qty", $$v)
                                            },
                                            expression: "ingredient.qty"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", sm: "6", md: "4" }
                                      },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            dense: "",
                                            items: _vm.unidadMedida,
                                            "item-text": "label",
                                            "item-value": "value",
                                            label: "Medida",
                                            outlined: ""
                                          },
                                          model: {
                                            value: ingredient.units,
                                            callback: function($$v) {
                                              _vm.$set(ingredient, "units", $$v)
                                            },
                                            expression: "ingredient.units"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", sm: "6", md: "4" }
                                      },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            dense: "",
                                            items: _vm.varianteIng,
                                            "item-text": "label",
                                            "item-value": "value",
                                            label: "Variante",
                                            outlined: ""
                                          },
                                          model: {
                                            value: ingredient.variant,
                                            callback: function($$v) {
                                              _vm.$set(
                                                ingredient,
                                                "variant",
                                                $$v
                                              )
                                            },
                                            expression: "ingredient.variant"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                ingredient.allergen_id
                                  ? _c("v-col", { attrs: { cols: "12" } }, [
                                      _c("div", { staticClass: "subtitle" }, [
                                        _vm._v(
                                          "Alérgeno:\n                  " +
                                            _vm._s(
                                              _vm.getAllergenById(
                                                ingredient.allergen_id
                                              ).name
                                            )
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                ingredient.allergen_id
                                  ? [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              label:
                                                "Nombre ingrediente alternativo",
                                              items: _vm.ingredientsWithoutAllergen(
                                                ingredient.allergen_id
                                              ),
                                              "item-text": "name",
                                              "item-value": "id",
                                              outlined: ""
                                            },
                                            model: {
                                              value:
                                                ingredient.alternative
                                                  .ingredientId,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  ingredient.alternative,
                                                  "ingredientId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "ingredient.alternative.ingredientId"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                md: "4"
                                              }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  type: "number",
                                                  outlined: "",
                                                  label: "Cantidad",
                                                  required: "",
                                                  hint: "Para 4 personas",
                                                  "persistent-hint": ""
                                                },
                                                model: {
                                                  value:
                                                    ingredient.alternative.qty,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      ingredient.alternative,
                                                      "qty",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "ingredient.alternative.qty"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                md: "4"
                                              }
                                            },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  dense: "",
                                                  items: _vm.unidadMedida,
                                                  "item-text": "label",
                                                  "item-value": "value",
                                                  label: "Medida",
                                                  outlined: ""
                                                },
                                                model: {
                                                  value:
                                                    ingredient.alternative
                                                      .units,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      ingredient.alternative,
                                                      "units",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "ingredient.alternative.units"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                md: "4"
                                              }
                                            },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  dense: "",
                                                  items: _vm.varianteIng,
                                                  "item-text": "label",
                                                  "item-value": "value",
                                                  label: "Variante",
                                                  outlined: ""
                                                },
                                                model: {
                                                  value:
                                                    ingredient.alternative
                                                      .variant,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      ingredient.alternative,
                                                      "variant",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "ingredient.alternative.variant"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  : _vm._e(),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c("v-switch", {
                                      staticClass: "ma-2",
                                      attrs: {
                                        color: "primary",
                                        label: "Es un ingrediente estrella"
                                      },
                                      model: {
                                        value: ingredient.starred,
                                        callback: function($$v) {
                                          _vm.$set(ingredient, "starred", $$v)
                                        },
                                        expression: "ingredient.starred"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                ingredient.allergen_id
                                  ? _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c("v-switch", {
                                          staticClass: "ma-2",
                                          attrs: {
                                            color: "primary",
                                            label:
                                              "Este ingrediente implica una modificación de la receta"
                                          },
                                          model: {
                                            value:
                                              ingredient.impliesRecipeModification,
                                            callback: function($$v) {
                                              _vm.$set(
                                                ingredient,
                                                "impliesRecipeModification",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "ingredient.impliesRecipeModification"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              2
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "pa-4" },
                    [
                      _c("div", { staticClass: "flex-grow-1" }),
                      _c(
                        "v-btn",
                        {
                          attrs: { depressed: "", color: "gray", text: "" },
                          on: {
                            click: function($event) {
                              _vm.dialogGroupIngredient = false
                            }
                          }
                        },
                        [_vm._v("Cancelar")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "success" },
                          on: {
                            click: function($event) {
                              return _vm.createGroupIngredient()
                            }
                          }
                        },
                        [_vm._v("Guardar")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "600px" },
              model: {
                value: _vm.dialogIngredient,
                callback: function($$v) {
                  _vm.dialogIngredient = $$v
                },
                expression: "dialogIngredient"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", color: "primary", flat: "" } },
                    [
                      _vm.stepMode === "create"
                        ? _c("v-toolbar-title", [_vm._v("Añadir ingrediente")])
                        : _c("v-toolbar-title", [_vm._v("Editar ingrediente")]),
                      _c("div", { staticClass: "flex-grow-1" }),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              _vm.dialogIngredient = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _vm.hasErrorIngredient
                                    ? _c(
                                        "v-alert",
                                        { attrs: { type: "error" } },
                                        [
                                          _vm._v(
                                            "Error añadiendo el ingrediente."
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      disabled: _vm.stepMode !== "create",
                                      label: "Nombre ingrediente",
                                      items: _vm.ingredients$.filter(function(
                                        ref
                                      ) {
                                        var vegan = ref.vegan

                                        return (
                                          (vegan && this$1.recipe.vegan) ||
                                          !this$1.recipe.vegan
                                        )
                                      }),
                                      "item-text": "name",
                                      "item-value": "id",
                                      outlined: ""
                                    },
                                    on: { change: _vm.selectedIngredient },
                                    model: {
                                      value: _vm.ingredient.ingredientId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ingredient,
                                          "ingredientId",
                                          $$v
                                        )
                                      },
                                      expression: "ingredient.ingredientId"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.ingredientType === "SIMPLE"
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              hint: "Para 4 personas",
                                              outlined: "",
                                              label: "Cantidad",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.ingredient.qty,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.ingredient,
                                                  "qty",
                                                  $$v
                                                )
                                              },
                                              expression: "ingredient.qty"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4"
                                          }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.unidadMedida,
                                              "item-text": "label",
                                              "item-value": "value",
                                              label: "Medida",
                                              outlined: ""
                                            },
                                            model: {
                                              value: _vm.ingredient.units,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.ingredient,
                                                  "units",
                                                  $$v
                                                )
                                              },
                                              expression: "ingredient.units"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4"
                                          }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.varianteIng,
                                              "item-text": "label",
                                              "item-value": "value",
                                              label: "Variante",
                                              outlined: ""
                                            },
                                            model: {
                                              value: _vm.ingredient.variant,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.ingredient,
                                                  "variant",
                                                  $$v
                                                )
                                              },
                                              expression: "ingredient.variant"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.ingredientType === "COMPOUND"
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              outlined: "",
                                              label: "Factor",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.ingredient.qty,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.ingredient,
                                                  "qty",
                                                  $$v
                                                )
                                              },
                                              expression: "ingredient.qty"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.selectedIngredientContainsAllergen
                                ? _c("v-col", { attrs: { cols: "12" } }, [
                                    _c("div", { staticClass: "subtitle" }, [
                                      _vm._v(
                                        "Alérgeno: " +
                                          _vm._s(_vm.selectedAllergen.name)
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm.selectedIngredientContainsAllergen
                                ? [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            disabled: _vm.stepMode !== "create",
                                            label:
                                              "Nombre ingrediente alternativo",
                                            items: _vm.ingredientsWithoutAllergen(),
                                            "item-text": "name",
                                            "item-value": "id",
                                            outlined: ""
                                          },
                                          on: {
                                            change:
                                              _vm.selectedAlternativeIngredient
                                          },
                                          model: {
                                            value:
                                              _vm.ingredient.alternative
                                                .ingredientId,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.ingredient.alternative,
                                                "ingredientId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "ingredient.alternative.ingredientId"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm.ingredientAlternativeType === "SIMPLE"
                                      ? _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: {
                                                  cols: "12",
                                                  sm: "6",
                                                  md: "4"
                                                }
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    type: "number",
                                                    outlined: "",
                                                    label: "Cantidad",
                                                    required: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.ingredient.alternative
                                                        .qty,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.ingredient
                                                          .alternative,
                                                        "qty",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ingredient.alternative.qty"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: {
                                                  cols: "12",
                                                  sm: "6",
                                                  md: "4"
                                                }
                                              },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    items: _vm.unidadMedida,
                                                    "item-text": "label",
                                                    "item-value": "value",
                                                    label: "Medida",
                                                    outlined: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.ingredient.alternative
                                                        .units,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.ingredient
                                                          .alternative,
                                                        "units",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ingredient.alternative.units"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: {
                                                  cols: "12",
                                                  sm: "6",
                                                  md: "4"
                                                }
                                              },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    items: _vm.varianteIng,
                                                    "item-text": "label",
                                                    "item-value": "value",
                                                    label: "Variante",
                                                    outlined: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.ingredient.alternative
                                                        .variant,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.ingredient
                                                          .alternative,
                                                        "variant",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ingredient.alternative.variant"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.ingredientAlternativeType === "COMPOUND"
                                      ? _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    outlined: "",
                                                    label: "Factor",
                                                    required: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.ingredient.alternative
                                                        .qty,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.ingredient
                                                          .alternative,
                                                        "qty",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ingredient.alternative.qty"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                : _vm._e(),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-switch", {
                                    staticClass: "ma-2",
                                    attrs: {
                                      color: "primary",
                                      label: "Es un ingrediente estrella"
                                    },
                                    model: {
                                      value: _vm.ingredient.starred,
                                      callback: function($$v) {
                                        _vm.$set(_vm.ingredient, "starred", $$v)
                                      },
                                      expression: "ingredient.starred"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.selectedIngredientContainsAllergen
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-switch", {
                                        staticClass: "ma-2",
                                        attrs: {
                                          color: "primary",
                                          label:
                                            "Este ingrediente implica una modificación de la receta"
                                        },
                                        model: {
                                          value:
                                            _vm.ingredient
                                              .impliesRecipeModification,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.ingredient,
                                              "impliesRecipeModification",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ingredient.impliesRecipeModification"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "pa-4" },
                    [
                      _c("div", { staticClass: "flex-grow-1" }),
                      _c(
                        "v-btn",
                        {
                          attrs: { depressed: "", color: "gray", text: "" },
                          on: {
                            click: function($event) {
                              _vm.dialogIngredient = false
                              _vm.selectedIngredientContainsAllergen = false
                            }
                          }
                        },
                        [_vm._v("Cancelar")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "success" },
                          on: {
                            click: function($event) {
                              return _vm.createIngredient()
                            }
                          }
                        },
                        [_vm._v("Guardar")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "800px" },
              model: {
                value: _vm.dialogStep,
                callback: function($$v) {
                  _vm.dialogStep = $$v
                },
                expression: "dialogStep"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", color: "primary", flat: "" } },
                    [
                      _vm.stepMode === "create"
                        ? _c("v-toolbar-title", [_vm._v("Añadir paso")])
                        : _c("v-toolbar-title", [_vm._v("Editar paso")]),
                      _c("div", { staticClass: "flex-grow-1" }),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              _vm.dialogStep = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _vm.hasErrorStep
                                    ? _c(
                                        "v-alert",
                                        { attrs: { type: "error" } },
                                        [_vm._v("Error añadiendo el paso.")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.tipoPaso,
                                      "item-text": "label",
                                      "item-value": "value",
                                      label: "Tipo de paso",
                                      outlined: ""
                                    },
                                    model: {
                                      value: _vm.step.step,
                                      callback: function($$v) {
                                        _vm.$set(_vm.step, "step", $$v)
                                      },
                                      expression: "step.step"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      label: "Título",
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.step.title,
                                      callback: function($$v) {
                                        _vm.$set(_vm.step, "title", $$v)
                                      },
                                      expression: "step.title"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      outlined: "",
                                      name: "input-7-1",
                                      label: "Descripción"
                                    },
                                    model: {
                                      value: _vm.step.description,
                                      callback: function($$v) {
                                        _vm.$set(_vm.step, "description", $$v)
                                      },
                                      expression: "step.description"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          (_vm.stepTypeCreation === "PREVIOUS_PREPARATION"
                          ? _vm.ingredientsPreparacion.length
                          : _vm.ingredientsElaboracion.length +
                            _vm.ingredientsPreparacion.length)
                            ? _c("p", [
                                _vm._v(
                                  "Ingredientes que son necesários en este paso"
                                )
                              ])
                            : _c("p", [_vm._v("No hay ingredientes creados!")]),
                          _c(
                            "v-row",
                            _vm._l(
                              _vm.stepTypeCreation === "PREVIOUS_PREPARATION" &&
                                _vm.step.alternative &&
                                  _vm.step.alternative.length
                                ? _vm.ingredientsPreparacion
                                : _vm.ingredientsPreparacion.concat(
                                    _vm.ingredientsElaboracion
                                  ),
                              function(ingredient, index) {
                                return _c(
                                  "v-col",
                                  {
                                    key: index,
                                    staticStyle: {
                                      "padding-top": "0",
                                      "padding-bottom": "0"
                                    },
                                    attrs: { cols: "6" }
                                  },
                                  [
                                    _vm.step.alternative &&
                                    _vm.step.alternative.length &&
                                    _vm.step.alternative[index]
                                      ? _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticStyle: {
                                                  "padding-top": "0",
                                                  "padding-bottom": "0"
                                                },
                                                attrs: { cols: "12" }
                                              },
                                              [
                                                _c("v-switch", {
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.selectIngredientId(
                                                        ingredient,
                                                        index
                                                      )
                                                    }
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "label",
                                                        fn: function() {
                                                          return [
                                                            _c("div", [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    ingredient.title
                                                                  ) +
                                                                  " (" +
                                                                  _vm._s(
                                                                    ingredient.qty
                                                                  ) +
                                                                  "\n                          " +
                                                                  _vm._s(
                                                                    _vm.unidadMedida.find(
                                                                      function(
                                                                        unit
                                                                      ) {
                                                                        return (
                                                                          unit.value ===
                                                                          ingredient.units
                                                                        )
                                                                      }
                                                                    ).label
                                                                  ) +
                                                                  ")\n                          "
                                                              ),
                                                              ingredient.group
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "- " +
                                                                        _vm._s(
                                                                          ingredient.group
                                                                        )
                                                                    )
                                                                  ])
                                                                : _vm._e(),
                                                              ingredient.alternative &&
                                                              ingredient
                                                                .alternative
                                                                .allergen
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "(alérgeno: " +
                                                                        _vm._s(
                                                                          ingredient
                                                                            .alternative
                                                                            .allergen
                                                                        ) +
                                                                        ")\n                            - alternativo:\n                            " +
                                                                        _vm._s(
                                                                          ingredient
                                                                            .alternative
                                                                            .title
                                                                        )
                                                                    )
                                                                  ])
                                                                : _vm._e()
                                                            ])
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.step.alternative[
                                                        index
                                                      ].active,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.step.alternative[
                                                          index
                                                        ],
                                                        "active",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "step.alternative[index].active"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            ingredient.alternative &&
                                            _vm.step.alternative[index] &&
                                            _vm.step.alternative[index]
                                              .active &&
                                            ingredient.impliesRecipeModification
                                              ? _c(
                                                  "v-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c("v-textarea", {
                                                      attrs: {
                                                        outlined: "",
                                                        name: "input-7-1",
                                                        label:
                                                          "Descripción ingrediente alternativo"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.step.alternative[
                                                            index
                                                          ].description,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.step
                                                              .alternative[
                                                              index
                                                            ],
                                                            "description",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "step.alternative[index].description"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                          _vm.isErrorModal
                            ? _c("v-alert", { attrs: { type: "error" } }, [
                                _vm._v(_vm._s(_vm.errorMessage))
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("div", { staticClass: "flex-grow-1" }),
                      _c(
                        "v-btn",
                        {
                          attrs: { depressed: "", color: "gray", text: "" },
                          on: {
                            click: function($event) {
                              _vm.dialogStep = false
                            }
                          }
                        },
                        [_vm._v("Cancelar")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "success" },
                          on: {
                            click: function($event) {
                              return _vm.createStep()
                            }
                          }
                        },
                        [_vm._v("Guardar")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", [
                _vm.recipe.imageUrl
                  ? _c("img", {
                      staticClass: "imagen",
                      attrs: { src: _vm.recipe.imageUrl }
                    })
                  : _vm._e()
              ]),
              _c(
                "v-col",
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "background-color": "white",
                          label: "Titulo receta",
                          placeholder: "",
                          outlined: ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.updateRecipe()
                          }
                        },
                        model: {
                          value: _vm.recipe.title,
                          callback: function($$v) {
                            _vm.$set(_vm.recipe, "title", $$v)
                          },
                          expression: "recipe.title"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          type: "number",
                          suffix: "min",
                          "background-color": "white",
                          label: "Tiempo de preparación",
                          placeholder: "",
                          outlined: ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.updateRecipe()
                          }
                        },
                        model: {
                          value: _vm.recipe.preparationTime,
                          callback: function($$v) {
                            _vm.$set(_vm.recipe, "preparationTime", $$v)
                          },
                          expression: "recipe.preparationTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          multiple: "",
                          outlined: "",
                          chips: "",
                          label: "Tipo de receta",
                          items: _vm.tipoReceta,
                          "item-text": "label",
                          "item-value": "value"
                        },
                        on: {
                          change: function($event) {
                            return _vm.updateRecipe()
                          }
                        },
                        model: {
                          value: _vm.recipe.types,
                          callback: function($$v) {
                            _vm.$set(_vm.recipe, "types", $$v)
                          },
                          expression: "recipe.types"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: [
                            { text: "Vegana", value: true },
                            { text: "Flexible", value: false }
                          ],
                          "item-text": "text",
                          "item-value": "value",
                          label: "Tipo de dieta",
                          outlined: ""
                        },
                        model: {
                          value: _vm.recipe.vegan,
                          callback: function($$v) {
                            _vm.$set(_vm.recipe, "vegan", $$v)
                          },
                          expression: "recipe.vegan"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-file-input", {
                        attrs: { outlined: "", label: "Añadir imagen" },
                        on: { change: _vm.onFilePicked }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "title primary--text my-4" }, [
            _vm._v("Ingredientes")
          ]),
          _c(
            "div",
            { staticClass: "d-flex align-center justify-space-between my-6" },
            [
              _c(
                "div",
                { staticClass: "title", staticStyle: { width: "180px" } },
                [_vm._v("Preparación previa")]
              ),
              _c(
                "div",
                { staticClass: "d-flex align-center justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: { small: "", color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.addGroupIngredients("PREVIOUS_PREPARATION")
                        }
                      }
                    },
                    [_vm._v("Añadir grupo de ingredientes")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: { small: "", color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.addIngredient("PREVIOUS_PREPARATION")
                        }
                      }
                    },
                    [_vm._v("Añadir ingrediente")]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "v-flex",
            { staticClass: "mb-4", attrs: { xs12: "" } },
            [
              _c(
                "v-toolbar",
                {
                  attrs: { color: "secondary", dark: "", flat: "", dense: "" }
                },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12", sm: "1" } }, [
                        _c("div", { staticClass: "caption" })
                      ]),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", sm: "1" }
                        },
                        [
                          _c("div", { staticClass: "caption" }, [
                            _vm._v("Nombre")
                          ])
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", sm: "1" }
                        },
                        [
                          _c("div", { staticClass: "caption" }, [
                            _vm._v("Grupo")
                          ])
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", sm: "1" }
                        },
                        [
                          _c("div", { staticClass: "caption" }, [
                            _vm._v("Cantidad")
                          ])
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", sm: "1" }
                        },
                        [
                          _c("div", { staticClass: "caption" }, [
                            _vm._v("Peso")
                          ])
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", sm: "1" }
                        },
                        [
                          _c("div", { staticClass: "caption" }, [
                            _vm._v("Alérgeno")
                          ])
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", sm: "1" }
                        },
                        [
                          _c("div", { staticClass: "caption" }, [
                            _vm._v("Alternativa")
                          ])
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", sm: "1" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "caption",
                              attrs: {
                                title:
                                  "ingrediente incluido en lista de la compra"
                              }
                            },
                            [_vm._v("I.I.L.C")]
                          )
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", sm: "1" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "caption",
                              attrs: {
                                title:
                                  "alternativa incluida en lista de la compra"
                              }
                            },
                            [_vm._v("A.I.L.C")]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("StepsList", {
                attrs: {
                  steps: _vm.ingredientsPreparacion,
                  type: "ingredients"
                },
                on: {
                  move: function($event) {
                    return _vm.moveStep($event, "ingredients", "preparacion")
                  },
                  remove: function($event) {
                    return _vm.removeStep($event, "ingredients", "preparacion")
                  },
                  edit: function($event) {
                    return _vm.editIngredient($event, "PREVIOUS_PREPARATION")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex align-center justify-space-between my-6" },
            [
              _c(
                "div",
                { staticClass: "title", staticStyle: { width: "180px" } },
                [_vm._v("Elaboración")]
              ),
              _c(
                "div",
                { staticClass: "d-flex align-center justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: { small: "", color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.addGroupIngredients("MAIN_PREPARATION")
                        }
                      }
                    },
                    [_vm._v("Añadir grupo de ingredientes")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: { small: "", color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.addIngredient("MAIN_PREPARATION")
                        }
                      }
                    },
                    [_vm._v("Añadir ingrediente")]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "v-flex",
            { staticClass: "mb-4", attrs: { xs12: "" } },
            [
              _c(
                "v-toolbar",
                {
                  attrs: { color: "secondary", dark: "", flat: "", dense: "" }
                },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12", sm: "1" } }, [
                        _c("div", { staticClass: "caption" })
                      ]),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", sm: "1" }
                        },
                        [
                          _c("div", { staticClass: "caption" }, [
                            _vm._v("Nombre")
                          ])
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", sm: "1" }
                        },
                        [
                          _c("div", { staticClass: "caption" }, [
                            _vm._v("Grupo")
                          ])
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", sm: "1" }
                        },
                        [
                          _c("div", { staticClass: "caption" }, [
                            _vm._v("Cantidad")
                          ])
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", sm: "1" }
                        },
                        [
                          _c("div", { staticClass: "caption" }, [
                            _vm._v("Peso")
                          ])
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", sm: "1" }
                        },
                        [
                          _c("div", { staticClass: "caption" }, [
                            _vm._v("Alérgeno")
                          ])
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", sm: "1" }
                        },
                        [
                          _c("div", { staticClass: "caption" }, [
                            _vm._v("Alternativa")
                          ])
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", sm: "1" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "caption",
                              attrs: {
                                title:
                                  "ingrediente incluido en lista de la compra"
                              }
                            },
                            [_vm._v("I.I.L.C")]
                          )
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", sm: "1" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "caption",
                              attrs: {
                                title:
                                  "alternativa incluida en lista de la compra"
                              }
                            },
                            [_vm._v("A.I.L.C")]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("StepsList", {
                attrs: {
                  steps: _vm.ingredientsElaboracion,
                  type: "ingredients"
                },
                on: {
                  move: function($event) {
                    return _vm.moveStep(
                      $event,
                      "ingredients",
                      "MAIN_PREPARATION"
                    )
                  },
                  remove: function($event) {
                    return _vm.removeStep(
                      $event,
                      "ingredients",
                      "MAIN_PREPARATION"
                    )
                  },
                  edit: function($event) {
                    return _vm.editIngredient($event, "MAIN_PREPARATION")
                  }
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "title primary--text my-4" }, [
            _vm._v("Pasos")
          ]),
          _c(
            "div",
            { staticClass: "d-flex justify-space-between align-center my-6" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("Preparación")]),
              _c(
                "v-btn",
                {
                  staticClass: "ml-2",
                  attrs: { small: "", color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.addStep("PREVIOUS_PREPARATION")
                    }
                  }
                },
                [_vm._v("Añadir paso")]
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "mb-4", attrs: { xs12: "" } },
            [
              _c(
                "v-toolbar",
                {
                  attrs: { color: "secondary", dark: "", flat: "", dense: "" }
                },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12", sm: "1" } }, [
                        _c("div", { staticClass: "body-1" }, [_vm._v("Paso")])
                      ]),
                      _c("v-col", { attrs: { cols: "12", sm: "2" } }, [
                        _c("div", { staticClass: "body-1" }, [
                          _vm._v("Preparación")
                        ])
                      ]),
                      _c("v-col", { attrs: { cols: "12", sm: "2" } }, [
                        _c("div", { staticClass: "body-1" }, [
                          _vm._v("Ingredientes")
                        ])
                      ]),
                      _c("v-col", { attrs: { cols: "12", sm: "1" } }, [
                        _c("div", { staticClass: "body-1" }, [
                          _vm._v("Cantidad")
                        ])
                      ]),
                      _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
                        _c("div", { staticClass: "body-1" }, [
                          _vm._v("Descripción")
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("StepsList", {
                attrs: { steps: _vm.stepsPreparacion, type: "steps" },
                on: {
                  move: function($event) {
                    return _vm.moveStep($event, "steps", "PREVIOUS_PREPARATION")
                  },
                  remove: function($event) {
                    return _vm.removeStep(
                      $event,
                      "steps",
                      "PREVIOUS_PREPARATION"
                    )
                  },
                  edit: function($event) {
                    return _vm.editStep($event, "steps", "PREVIOUS_PREPARATION")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex justify-space-between align-center my-6" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("Elaboración")]),
              _c(
                "v-btn",
                {
                  staticClass: "ml-2",
                  attrs: { small: "", color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.addStep("MAIN_PREPARATION")
                    }
                  }
                },
                [_vm._v("Añadir paso")]
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "mb-4", attrs: { xs12: "" } },
            [
              _c(
                "v-toolbar",
                {
                  attrs: { color: "secondary", dark: "", flat: "", dense: "" }
                },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12", sm: "1" } }, [
                        _c("div", { staticClass: "body-1" }, [_vm._v("Paso")])
                      ]),
                      _c("v-col", { attrs: { cols: "12", sm: "2" } }, [
                        _c("div", { staticClass: "body-1" }, [
                          _vm._v("Preparación")
                        ])
                      ]),
                      _c("v-col", { attrs: { cols: "12", sm: "2" } }, [
                        _c("div", { staticClass: "body-1" }, [
                          _vm._v("Ingredientes")
                        ])
                      ]),
                      _c("v-col", { attrs: { cols: "12", sm: "1" } }, [
                        _c("div", { staticClass: "body-1" }, [
                          _vm._v("Cantidad")
                        ])
                      ]),
                      _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
                        _c("div", { staticClass: "body-1" }, [
                          _vm._v("Descripción")
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("StepsList", {
                attrs: { steps: _vm.stepsElaboracion, type: "steps" },
                on: {
                  move: function($event) {
                    return _vm.moveStep($event, "steps", "MAIN_PREPARATION")
                  },
                  remove: function($event) {
                    return _vm.removeStep($event, "steps", "MAIN_PREPARATION")
                  },
                  edit: function($event) {
                    return _vm.editStep($event, "steps", "MAIN_PREPARATION")
                  }
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "title primary--text my-4" }, [
            _vm._v("Simplificación de la receta")
          ]),
          _c(
            "v-flex",
            [
              _c("v-textarea", {
                attrs: {
                  outlined: "",
                  name: "input-7-4",
                  label: "Simplificación de la receta"
                },
                on: {
                  change: function($event) {
                    return _vm.updateRecipe()
                  }
                },
                model: {
                  value: _vm.recipe.simplification,
                  callback: function($$v) {
                    _vm.$set(_vm.recipe, "simplification", $$v)
                  },
                  expression: "recipe.simplification"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("v-switch", {
                staticClass: "ma-2",
                attrs: { color: "primary", label: "Visible en el recetario" },
                on: {
                  change: function($event) {
                    return _vm.updateRecipe()
                  }
                },
                model: {
                  value: _vm.recipe.published,
                  callback: function($$v) {
                    _vm.$set(_vm.recipe, "published", $$v)
                  },
                  expression: "recipe.published"
                }
              })
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "text-white",
              attrs: { color: "secondary", to: "/recetas" }
            },
            [
              _c("v-icon", { attrs: { left: "", dark: "" } }, [
                _vm._v("mdi-arrow-left")
              ]),
              _vm._v("Volver\n    ")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }